export const appTypeAndImageLink = {
    methods: {
        getAppType(appType) {
            if (appType) {
                const appTypeLower = appType.toLowerCase()
                if (appTypeLower.includes('jupyterlab')) {
                    return 'jupyterlab'
                } else if (appTypeLower.includes('rstudio')) {
                    return 'rstudio'
                } else if (appTypeLower.includes('spyder')) {
                    return 'spyder'
                } else if (appTypeLower.includes('matlab')) {
                    return 'matlab'
                } else if (appTypeLower.includes('stata')) {
                    return 'stata'
                } else if (appTypeLower.includes('sas')) {
                    return 'sas'
                } else if (appTypeLower.includes('octave')) {
                    return 'octave'
                } else if (appTypeLower.includes('spss')) {
                    return 'spss'
                } else if (appTypeLower.includes('vscode')) {
                    return 'vscode'
                } else if (appTypeLower.includes('other')) {
                    return 'other'
                } else {
                    return 'jupyterlab'
                }
            } else {
                return 'jupyterlab'
            }
        },
        getAppLink(appType) {
            if (appType) {
                const appTypeLower = appType.toLowerCase()
                if (appTypeLower.includes('jupyterlab')) {
                    return require('@/assets/jupyter-logo.svg')
                } else if (appTypeLower.includes('rstudio')) {
                    return require('@/assets/rstudio-logo.png')
                } else if (appTypeLower.includes('spyder')) {
                    return require('@/assets/spyder-logo.png')
                } else if (appTypeLower.includes('matlab')) {
                    return require('@/assets/matlab-logo.png')
                } else if (appTypeLower.includes('stata')) {
                    return require('@/assets/stata-logo.png')
                } else if (appTypeLower.includes('sas')) {
                    return require('@/assets/sas-logo.png')
                } else if (appTypeLower.includes('octave')) {
                    return require('@/assets/octave-logo.svg')
                } else if (appTypeLower.includes('spss')) {
                    return require('@/assets/spss-logo.png')
                } else if (appTypeLower.includes('vscode')) {
                    return require('@/assets/vscode-logo.png')
                } else if (appTypeLower.includes('other')) {
                    return require('@/assets/apps-icon.svg')
                } else {
                    return require('@/assets/jupyter-logo.svg')
                }
            } else {
                return require('@/assets/jupyter-logo.svg')
            }
        }
    }
}
